var en = function(n, ord
) {
  var s = String(n).split('.'), v0 = !s[1], t0 = Number(s[0]) == n,
      n10 = t0 && s[0].slice(-1), n100 = t0 && s[0].slice(-2);
  if (ord) return (n10 == 1 && n100 != 11) ? 'one'
      : (n10 == 2 && n100 != 12) ? 'two'
      : (n10 == 3 && n100 != 13) ? 'few'
      : 'other';
  return (n == 1 && v0) ? 'one' : 'other';
};
var number = function (value, name, offset) {
  if (!offset) return value;
  if (isNaN(value)) throw new Error("Can't apply offset:" + offset + ' to argument `' + name + '` with non-numerical value ' + JSON.stringify(value) + '.');
  return value - offset;
};
var plural = function (value, offset, lcfunc, data, isOrdinal) {
  if ({}.hasOwnProperty.call(data, value)) return data[value];
  if (offset) value -= offset;
  var key = lcfunc(value, isOrdinal);
  return key in data ? data[key] : data.other;
};
var select = function (value, data) {
  return {}.hasOwnProperty.call(data, value) ? data[value] : data.other;
};

module.exports = {
  generic: {
    results: function(d) { return plural(d.copy, 0, en, { "0": "0 Results", "1": "1 Result", other: number(d.copy, "copy") + " Results" }); }
  },
  search: {
    title: function(d) { return "Search Results"; },
    results: function(d) { return "“" + d.searchTerm + "”"; }
  },
  product: {
    notStockedOnLine: function(d) { return "Sorry, this item isn't available for purchase"; },
    productCode: function(d) { return "Product Code"; },
    soldOut: function(d) { return "Sorry, this item is currently unavailable. Keep track of this style so you don't miss out if it becomes available again"; },
    buyableFalse: function(d) { return "Sorry, this item isn’t available for purchase"; },
    unavailableProduct: function(d) { return "Sorry, this item isn’t available for purchase"; },
    shippingRestriction: function(d) { return "Sorry, this item is not available for purchase to your location"; },
    restrictedToEip: function(d) { return "Sorry, this item is currently unavailable. Keep track of this style by adding it to your Wish List"; },
    doNotForgetToAddToWishlistMessage: function(d) { return "Don’t forget to add your item to the Wish List"; },
    shippingRestrictionsNotificationMessage: function(d) { return "Shipping restrictions may apply. [Find out more](/" + d.locale + "/content/help/delivery/)"; },
    needAdvice: function(d) { return "Need advice?"; },
    needAdviceCTA: function(d) { return "Contact Customer Care"; },
    finalSaleNotification: function(d) { return "Final Sale - This item is non-returnable"; },
    genericMessage: function(d) { return ""; },
    Brand: function(d) { return "BRAND"; },
    Illustrative: function(d) { return "ILLUSTRATIVE"; },
    productTitle: function(d) { return "Product title"; }
  },
  considered: {
    badge: function(d) { return "Considered"; },
    consideredAccordion: function(d) { return "We're proud to include this item in our Considered Edit as part of our commitment to make responsible fashion more widely available to our customers. "; },
    actionText: function(d) { return "Learn more"; },
    href: function(d) { return "/editorial/our-considered-pledge"; },
    consideredAttributesTitle: function(d) { return "What makes it Considered?"; },
    consideredDelivery: function(d) { return "This is currently the delivery option with the lowest carbon footprint"; },
    consideredFilters: function(d) { return "Show All Considered"; }
  },
  colourAndSize: {
    colour: function(d) { return "Color"; },
    size: function(d) { return "size"; },
    button1: function(d) { return "hello"; },
    button2: function(d) { return "hello"; },
    line1: function(d) { return "hello"; },
    line2: function(d) { return "hello"; }
  },
  pagination: {
    first: function(d) { return "First"; },
    previous: function(d) { return "Previous"; },
    next: function(d) { return "Next"; },
    last: function(d) { return "Last"; },
    status: function(d) { return "Page " + d.currentPage + " of " + d.totalPages; }
  },
  contactUsCTA: {
    personalShopper: {
      title: function(d) { return "Speak to our Personal Shopping Team to be added to the waiting list for this item"; },
      titleAssignedPS: function(d) { return "Speak to " + d.name + " to be added to the waiting list for this item"; },
      emailLabel: function(d) { return "Email"; },
      emailStructure: {
        subject: function(d) { return "Please add me to the waiting list for " + d.name; },
        body: function(d) { return "Please add me to the waiting list for this product: " + d.url; }
      }
    },
    sizeAndFit: {
      title: function(d) { return "Still not sure what size to purchase?"; },
      titleEip: function(d) { return "Our Personal Shopping Team is on hand to provide detailed product information."; },
      titlePersonalShopper: function(d) { return "Your Personal Shopper is on hand to provide detailed product information."; },
      emailLabel: function(d) { return "Email"; },
      emailStructure: {
        subject: function(d) { return "I would like your advice for " + d.name; },
        body: function(d) { return "I am still not sure about sizing for this product: " + d.url; }
      }
    },
    badge: function(d) { return "CONTACT PERSONAL SHOPPER"; },
    eip: {
      soldOutOnline: {
        title: function(d) { return "Contact our Personal Shopping Team for help finding an alternative"; },
        titleAssignedPS: function(d) { return "Contact " + d.name + " for help finding an alternative"; },
        emailLabel: function(d) { return "Email"; },
        emailStructure: {
          subject: function(d) { return "I would like to know alternatives for " + d.name + " (Sold Out)"; },
          body: function(d) { return "I would like help to find an alternative for this sold out product: " + d.url; }
        }
      },
      notStockedOnline: {
        title: function(d) { return "Contact our Personal Shopping Team about purchasing this item"; },
        titleAssignedPS: function(d) { return "Contact " + d.name + " about purchasing this item"; },
        emailLabel: function(d) { return "Email"; },
        emailStructure: {
          subject: function(d) { return "I would like to purchase " + d.name; },
          body: function(d) { return "I would like to purchase this product: " + d.url; }
        }
      },
      buyableFalse: {
        title: function(d) { return "Contact our Personal Shopping Team about purchasing this item"; },
        titleAssignedPS: function(d) { return "Contact " + d.name + " about purchasing this item"; },
        emailLabel: function(d) { return "Email"; },
        emailStructure: {
          subject: function(d) { return "I would like to purchase " + d.name; },
          body: function(d) { return "I would like to purchase this product: " + d.url; }
        }
      },
      shippingRestricted: {
        title: function(d) { return "Contact our Personal Shopping Team to help you place your order"; },
        titleAssignedPS: function(d) { return "Contact " + d.name + " to help you place your order"; },
        emailLabel: function(d) { return "Email"; },
        emailStructure: {
          subject: function(d) { return "Please help me to place an order for " + d.name; },
          body: function(d) { return "Please help me to place an order for this product: " + d.url; }
        }
      },
      "default": {
        title: function(d) { return "Need sizing advice, a second opinion, or can’t find your size? Our Personal Shopping Team is here to help"; },
        titleAssignedPS: function(d) { return "Need sizing advice, a second opinion, or can’t find your size? " + d.name + " is here to help"; },
        emailLabel: function(d) { return "Email"; },
        emailStructure: {
          subject: function(d) { return "I would like your support " + d.name; },
          body: function(d) { return "I would like your support: " + d.url; }
        }
      },
      badge: function(d) { return "CONTACT PERSONAL SHOPPER"; }
    }
  },
  ctaButtons: {
    goToWishlist: function(d) { return "Go to wish list"; },
    addToWishlist: function(d) { return "Add to Wish List"; },
    addToWishlistLoading: function(d) { return "Adding to wishlist..."; },
    addToBag: function(d) { return "Add to Bag"; },
    goToBag: function(d) { return "Go to Bag"; },
    addToBagLoading: function(d) { return "Adding to bag..."; },
    viewShoppingBag: function(d) { return "View Shopping Bag"; },
    viewMoreDetails: function(d) { return "View More Details"; }
  },
  price: {
    discountPercentage: function(d) { return "(-" + d.discountPercentage + "%)"; },
    discountPrice: function(d) { return "discount price"; },
    currencyExchange: function(d) { return "(Approx " + d.exchangeCurrency + " " + d.exchangePrice + ")"; },
    ddpInformation: function(d) { return "(taxes and duties included)"; },
    wasPriceOriginal: function(d) { return "RRP: " + d.wasPrice; },
    wasPrice: function(d) { return "RRP:"; },
    originalPrice: function(d) { return "Original price:"; },
    minimumPrice: function(d) { return "Lowest price in 30 days:"; },
    minimumPriceEqual: function(d) { return "This is the lowest price in 30 days"; },
    priceInformation: {
      title: function(d) { return "Pricing Information"; },
      subtitle: function(d) { return "Get familiar with THE OUTNET price display:"; },
      descriptionRRP: function(d) { return "**1. Current price:**\nThe price at which the product is offered exclusively on THE OUTNET app and website\n\n**2. Lowest price in 30 days:**\nThe lowest price at which the product was on sale in the last 30 days\n\n**3. RRP:**\nThe recommended retail price by the manufacturer\n\n[Find Out More](" + d.link + ")"; },
      descriptionOriginalPrice: function(d) { return "**1. Current price:**\nThe price at which the product is offered exclusively on THE OUTNET app and website\n\n**2. Lowest price in 30 days:**\nThe lowest price at which the product was on sale in the last 30 days\n\n**3. Original price:**\nThe price the item was first listed at on THE OUTNET app and website\n\n[Find Out More](" + d.link + ")"; },
      descriptionRRPWithoutMinimumMessage: function(d) { return "**1. Current price:**\nThe price at which the product is offered exclusively on THE OUTNET app and website\n\n**2. RRP:**\nThe recommended retail price by the manufacturer\n\n[Find Out More](" + d.link + ")"; },
      descriptionOriginalPriceWithoutMinimumMessage: function(d) { return "**1. Current price:**\nThe price at which the product is offered exclusively on THE OUTNET app and website\n\n**2. Original price:**\nThe price the item was first listed at on THE OUTNET app and website\n\n[Find Out More](" + d.link + ")"; },
      descriptionThirdPartyUs: function(d) { return "**1. Current price:**\nThis is the price at which the product is currently offered on THE OUTNET app and website. The Current Price is influenced by various factors, including our cost, inventory levels, competitive market trends, and customer demand. This price is unique to our online store and may fluctuate based on promotional activities and stock availability\n\n**2. Reference price:**\nThe reference price is a price provided to us by the manufacturer or the retailer that supplied us with the product. This represents a suggested retail price for the product. It is not an indication of the price at which the product is sold by other retailers or outlets, which may vary. We encourage you to use the Reference Price as a guide in assessing the value of our Current Price.\n\nAt THE OUTNET we continuously monitor market dynamics to ensure our prices are competitive and reflective of the current market trends. Our aim is to provide quality products at prices that are fair and transparent, ensuring that you always receive the best value for your money.\n\n[Find Out More](" + d.link + ")"; }
    }
  },
  productListing: {
    unselectAll: function(d) { return "Unselect all"; },
    allSelected: function(d) { return "All"; },
    numberSelected: function(d) { return d.number + " Selected"; },
    refine: function(d) { return "Filter"; },
    priceFilter: {
      under: function(d) { return d.price; },
      over: function(d) { return d.price; }
    },
    apply: function(d) { return "Apply"; },
    clearFilters: function(d) { return "Clear filters"; },
    sortBy: function(d) { return "Sort By"; },
    topDesignersThisWeek: function(d) { return "Top Designers this week"; },
    azDesigners: function(d) { return "A-Z Designers"; },
    searchDesigner: function(d) { return "Search designer"; },
    noResultsSearch: function(d) { return "We have found no results for \"" + d.searchTerm + "\". Please try again."; },
    emptyList: function(d) { return select(d.pageType, { MARK_DOWN: "Sale is closed", DESIGNER: "New season product coming soon", SEARCH: "We have found no results for " + d.query, other: "No items found" }); },
    emptyListCopy: function(d) { return select(d.pageType, { SEARCH: "Please try another search or start browsing below", other: "" }); },
    emptyListGoTo: function(d) { return select(d.pageType, { other: "" }); },
    loadMore: function(d) { return "Load more"; },
    youHaveViewed: function(d) { return "You've viewed " + d.totalNumberOfViewedProducts + " of " + d.totalProducts + " products"; },
    loadPrevious: function(d) { return "Load previous"; },
    saleToggle: function(d) { return select(d.pageType, { DESIGNER: select(d.country, { IT: "Shop " + d.designerName + " Promotion", FR: "Shop " + d.designerName + " Promotion", LU: "Shop " + d.designerName + " Promotion", BE: "Shop " + d.designerName + " Promotion", other: "Shop " + d.designerName + " Sale" }), other: "Shop all " + d.designerName }); },
    notifyTitle: function(d) { return "Oops!"; },
    continueLink: function(d) { return "Shop Just In"; },
    wishList: {
      title: function(d) { return "Added to your Wish List"; },
      subtitle: function(d) { return "You can review your most desired pieces at any time in your account"; },
      goToWishlist: function(d) { return "Go to your Wish List"; }
    }
  },
  contentHeader: {
    viewMoreCopy: function(d) { return "more"; },
    sale: function(d) { return "Sale"; }
  },
  collapsableContent: {
    viewMore: function(d) { return "more"; },
    viewLess: function(d) { return "less"; }
  },
  sizes: {
    sizeLabel: function(d) { return "Size"; },
    oneSizeLabel: function(d) { return "One Size"; },
    initialValue: function(d) { return "Select your size"; },
    sizeConversion: function(d) { return "A " + d.sizeLabel + " is equivalent to a " + d.localLanguageType + " " + d.localSizeValue; },
    sizeConversionModal: function(d) { return "A **" + d.sizeLabel + "** is equivalent to a " + select(d.isSelected, { true: "**" + d.preferredSize + "**", other: d.preferredSize }); },
    sizeGuide: {
      viewProductMeasurements: function(d) { return "Size Guide"; },
      viewSizeGuide: function(d) { return "Size Guide"; },
      measuringGuide: function(d) { return "Size & Measurements"; },
      title: function(d) { return "Size Guide"; },
      sizeMeasurementsTitle: function(d) { return "Measurements"; },
      sizeMeasurementsDetails: function(d) { return "Each of our garments are measured by hand to help you find the perfect size."; },
      sizeConversionsTitle: function(d) { return "Size Information"; },
      sizeConversionsDetails: function(d) { return "Unsure what your size is? Check here."; },
      slideToScroll: function(d) { return "Slide to scroll"; },
      noFurtherInfo: function(d) { return "No Further Size Information"; },
      unavailable: function(d) { return "Unavailable"; },
      cm: function(d) { return "cm"; },
      "in": function(d) { return "in"; },
      compareSize: {
        text: function(d) { return ""; },
        subText: function(d) { return ""; }
      },
      inStock: function(d) { return "In Stock"; },
      lowStock: function(d) { return "Low Stock"; },
      oneLeft: function(d) { return "Only One Left"; },
      outOfStock: function(d) { return "Out of Stock"; }
    },
    watchSizeGuide: {
      title: function(d) { return ""; }
    }
  },
  seo: {
    azdesigners: {
      title: function(d) { return "Shop by fashion designer | THE OUTNET"; },
      description: function(d) { return ""; },
      keywords: function(d) { return ""; }
    },
    pdpMarkup: {
      nbColours: function(d) { return plural(d.copy, 0, en, { "1": ", available in " + d.color1 + ".", "2": ", available in " + d.color1 + " or " + d.color2 + ".", other: ", available in " + number(d.copy, "copy") + " colours." }); },
      productDescription: function(d) { return d.name + " in " + d.color + ". Size: " + d.size + ". Material: " + d.material + "."; }
    }
  },
  error: {
    notFound: function(d) { return "Sorry, can’t find that page."; },
    unknownError: function(d) { return "Something went wrong."; },
    goBack: function(d) { return "Go Back"; }
  },
  retry: {
    title: function(d) { return "We're sorry, this page isn't available."; },
    retrynow: function(d) { return "Retry now"; },
    timetoretry: function(d) { return "We'll try and reconnect you in " + d.seconds + " seconds..."; },
    retryingnow: function(d) { return "Retrying now"; }
  },
  accordionTitles: {
    editorsNotes: function(d) { return "Editor's Notes"; },
    sizeAndFit: function(d) { return "Size & Fit"; },
    technicalSpecification: function(d) { return "Technical Specification"; },
    detailsAndCare: function(d) { return "Details & Care"; },
    technicalDescription: function(d) { return "Details"; }
  },
  accordionIds: {
    editorsNotes: function(d) { return "EditorNotes"; },
    sizeAndFit: function(d) { return "SizeAndFit"; },
    detailsAndCare: function(d) { return "Details"; },
    technicalDescription: function(d) { return "DetailsAndCare"; }
  },
  sizeAndFit: {
    sizeDetails: function(d) { return select(d.slash, { slash: " / " + d.label + d.uom, other: d.label + d.uom }); }
  },
  detailsAndCare: {
    imported: function(d) { return "This item has been imported"; },
    madeIn: function(d) { return "Made in " + d.madeIn; }
  },
  notifications: {
    PAGE_NOT_AVAILABLE: {
      title: function(d) { return "Oops!"; },
      message: function(d) { return "We noticed you don't have the right permissions to access the sale, but don't worry, there's still lots of hidden gems to uncover in our new arrivals. While you wait, why not discover out latest arrivals?"; }
    },
    PAGE_CLOSED: {
      title: function(d) { return "Oops!"; },
      message: function(d) { return "We’re sorry, our sale has ended, but don’t worry there’s still lots of hidden gems to uncover in our new arrivals."; }
    },
    PAGE_SOON: {
      title: function(d) { return "Bright and early"; },
      message: function(d) { return "The early bird catches the worm, but you’re a little too early this time. While you wait, why not discover our latest arrivals? "; }
    },
    ADD_TO_BAG_MESSAGE_ERROR: function(d) { return "Failed on add to bag"; },
    authToAdd: function(d) { return "To add this item to your Wish List, please sign in or register"; },
    errors: function(d) { return select(d.error, { ADD_TO_WISHLIST_NO_SIZE_SELECTED_ERROR: " Please select a size.", ERR_WISH_LIST_NOT_EXISTS: " This Wish List has been removed. Add this item to another Wish List ", ERR_WISH_LIST_MAX_WISH_LIST_ITEMS_REACHED: " You've reached the maximum number of items in your Wish List. Remove some styles to add items again ", ERR_SKU_ALREADY_EXISTS: " This item is already in your Wish List ", NO_SIZE_SELECTED_ERROR: " Please select a size. ", TEMPORARY_ADD_TO_CART_ERROR: " Sorry, this item wasn't added to your Shopping Bag. Please try again. ", ADD_TO_CART_IDENTITY_GENERIC_ERROR: " Sorry, this item wasn't added to your Shopping Bag. Please try again. ", ADD_TO_CART_GENERIC_ERROR: " Sorry, this item wasn't added to your Shopping Bag. Please try again. ", CART_ITEM_OUT_OF_STOCK: "Sorry, this item has sold out ", CART_ITEM_INSUFFICIENT_STOCK: " Sorry, we don't have any more of this item available ", CART_ORDER_LINE_QTY_LIMIT: "Sorry, you’ve reached the maximum amount of items in your Shopping Bag", CART_ORDER_LINES_LIMIT: "Sorry, you’ve reached the maximum amount of items in your Shopping Bag", CWXFR0268E: "", NO_UBER_TOKEN_ERROR: "", other: "Unable to perform your request. Please try again." }); }
  },
  shopMore: {
    title: function(d) { return "View More"; }
  },
  wearItWith: {
    heading: function(d) { return "Wear it With"; }
  },
  youMayAlsoLike: {
    heading: function(d) { return "You may also like"; }
  },
  moreByThisDesigner: {
    heading: function(d) { return "More By This Designer"; }
  },
  recentlyViewed: {
    heading: function(d) { return "Recently Viewed"; }
  },
  noResultsSearchPage: {
    dividerHeading: function(d) { return "These trending items might interest you"; }
  },
  designers: {
    pageName: function(d) { return "Designers"; },
    favorite: function(d) { return "Favorite Designer"; },
    mens: function(d) { return "Men"; },
    womens: function(d) { return "Women"; }
  },
  designersWithSale: {
    pageName: function(d) { return "Sale Designers"; }
  },
  deliveryAndReturns: {
    title: function(d) { return "Delivery & Returns"; },
    description: function(d) { return "Find out more about our delivery options and how to exchange or return"; },
    shippingModesIntro: function(d) { return "Here are your delivery options:"; },
    shippingMode: function(d) { return select(d.shipModeCode, { STANDARD: " Standard ", EXPRESS: " Express ", NEXT_DAY_DELIVERY: " Next Day ", PREMIER_DAYTIME: " Premier DayTime ", PREMIER_EVENING: " Premier Evening ", other: "" }); },
    returnsInfo: function(d) { return "It’s easy to exchange or return your purchase, just make sure to send it back within " + select(d.country, { RU: "100", UA: "100", BY: "100", other: "28" }) + " days"; },
    findOutMoreLink: function(d) { return "Find out more"; }
  },
  errors: {
    empty: function(d) { return "This field is required"; },
    invalid: function(d) { return "Enter a valid " + d.field; }
  },
  accountUtility: {
    signupMessage: function(d) { return "\nSign up for our emails to be notified about future sales and other upcoming events."; },
    submit: function(d) { return "Submit"; },
    emailLabel: function(d) { return "Email Address"; }
  },
  recommendations: {
    viewSimilarItems: function(d) { return "View more"; }
  }
}