import { multipleRequestApiAction } from '@ynap/api-fetch-action';
import { getDesigners } from '@ynap/api-utils/src/catalogue';
import { getContentByPage } from '@ynap/api-utils/src/coremedia';
import { getCommerceConfig, getMessages } from '../selectors/getConfig';
import { FETCH_DESIGNERS_PREFIX, DESIGNERS_CONTENT_PREFIX } from '../constants';

export const fetchDesigners =
    (parentCategory, routeKey) =>
    (options = {}) => {
        return (dispatch, getState) => {
            const state = getState();

            const { language, langISO } = state.appState;
            const { services } = state.config.global;
            const { content: contentApi } = services;
            const { baseUrl: coremediaApibaseUrl, clientId: coremediaApiclientId } = contentApi;

            const { baseUrl, brandId, clientId, country, locale, uberToken } = getCommerceConfig(state);
            const messages = getMessages(state);
            options.messages = messages;
            options.locale = locale;

            const coreMediaOptions = {
                mv: 'gitMarkdown',
                f: 'ycos',
            };

            const coremediaRequest = getContentByPage({
                pageKey: 'azdesigners',
                brandId,
                baseUrl: coremediaApibaseUrl,
                country,
                language,
                clientId: coremediaApiclientId,
                options: coreMediaOptions,
                coreMediaLocale: langISO,
            });

            const designerRequest = getDesigners({
                baseUrl,
                brandId,
                country,
                uberToken,
                clientId,
                parentCategory,
                options: { locale: langISO },
            });

            const requests = [
                {
                    request: designerRequest,
                    prefix: FETCH_DESIGNERS_PREFIX,
                    uid: routeKey,
                    options,
                },
                {
                    request: coremediaRequest,
                    prefix: DESIGNERS_CONTENT_PREFIX,
                    required: false,
                    options: {
                        reqTimeout: 5000,
                    },
                },
            ];

            return dispatch(multipleRequestApiAction(requests));
        };
    };
