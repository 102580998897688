import { FounditContentState } from '../reducers/founditContentReducer';

export default function getFounditContent(content: FounditContentState) {
    const { response, error } = content;
    if (!response || !response.body || error) return null;
    if (response.body && Object.keys(response.body).length === 0) return null;

    if (response.body?.Links) {
        const links = response.body.Links?.['Main Links'];
        return links?.map(({ AnchorText, DestinationUrl, ImageUrl }) => {
            return {
                teaserTitle: AnchorText,
                href: DestinationUrl,
                picturesAndMedia: [
                    {
                        type: 'image',
                        imageUrl: ImageUrl,
                        height: 345,
                        width: 230,
                    },
                ],
                reloadPage: true,
            };
        });
    }
    return null;
}
