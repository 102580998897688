import {
    PRODUCT_LISTING_REQUEST,
    PRODUCT_LISTING_SUCCESS,
    PRODUCT_LISTING_FAILURE,
    PRODUCT_SIBLINGS_REQUEST,
    PRODUCT_SIBLINGS_SUCCESS,
    PRODUCT_SIBLINGS_FAILURE,
    RESET_STATE,
    RESET_PLP_STATE,
    PRODUCT_LISTING_MORE_REQUEST,
    PRODUCT_LISTING_MORE_SUCCESS,
    PRODUCT_LISTING_MORE_FAILURE,
    SET_SCROLL_ON_PLP,
    SET_NO_SET_SCROLL_ON_PLP,
    UNSET_UPDATE_PAGENUMBER,
    UNSET_SCROLL,
    SET_POSITION,
    AUTHENTICATED,
    SET_OPTIMIZELY_ALGORITHMIC_SORT_READY,
} from '../constants';
import { LOCATION_CHANGE } from 'connected-react-router';
import { parseUrl } from 'query-string';
import { isUserAllowed } from '@ynap/user-state';

const initialState = {
    visibleProducts: [], //products to display, array of { key: '/en-us/mens/whats-new?pageNumber=2', products: [...], loading: true}
    response: null,
    loading: false,
    error: false,
    appLoading: false,
    firstFetchedPageNumber: null, //for building load previous link
    lastFetchedPageNumber: null, //for building load more link
    scrollToPid: false, //for instructing PLP container to scroll
    updatePLPPageNumber: false, //for instructing PLP container to update page number in the url
    prevNumberOfPids: null, //for rendering pids skeleton on PLP
    fetchingMore: false, //to tell PLP container it's a pid only skeleton loading
    windowPosition: null, //window scroll position before pids recyling
    loadPrevious: false, //to tell PLP container it was load previous to do different scrolling
    recycle: false,
    optimizelyAlgorithmicSortReady: false,
    controller: typeof AbortController !== 'undefined' && new AbortController(),
};

//add more product list
function addProductList(originalProductLists, newProductList, previous) {
    let newProductListArray = originalProductLists.slice();
    if (newProductListArray.find((pl) => pl.key === newProductList.key)) {
        return newProductListArray.map((opl) => {
            if (opl.key !== newProductList.key) {
                return opl;
            }

            return {
                ...opl,
                ...newProductList,
            };
        });
    }

    if (previous) {
        newProductListArray.splice(0, 0, newProductList);
    } else {
        newProductListArray.splice(newProductListArray.length, 0, newProductList);
    }
    return newProductListArray;
}

//set the correct products, key and loading state for a product list
function getProductList(action, loading, prevNumberOfPids, numberOfSkeletonPids) {
    const numberOfSkeleton = numberOfSkeletonPids === 0 || numberOfSkeletonPids ? numberOfSkeletonPids : prevNumberOfPids ? prevNumberOfPids : 12;
    let authenticated = true;

    if (action?.body?.forceLogIn && (!action?.options?.user || !isUserAllowed(action?.body?.restrictedToSegments, action?.options?.user))) {
        authenticated = false;
    }

    return {
        products: loading ? new Array(numberOfSkeleton).fill() : action?.body?.products,
        key: action.uid,
        loading: authenticated ? loading : true,
    };
}

function mapProducts(products, siblings) {
    return products.map((p) => {
        const colors = siblings.find((ps) => ps.modelId === p.modelId);
        return { ...p, productSiblings: colors?.productColours };
    });
}

function removeSkeleton(visibleProducts) {
    return visibleProducts.map((pl) =>
        pl.loading
            ? {
                  ...pl,
                  loading: false,
              }
            : pl,
    );
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                visibleProducts: removeSkeleton(state.visibleProducts),
            };
        case LOCATION_CHANGE:
            return {
                ...state,
                appLoading: true,
            };
        case RESET_STATE:
            return initialState;

        case UNSET_SCROLL:
            return {
                ...state,
                scrollToPid: false,
            };

        case SET_POSITION:
            return {
                ...state,
                // scrollToPid: true,
                windowPosition: action.windowPosition,
            };

        case SET_SCROLL_ON_PLP:
            return {
                ...state,
                visibleProducts: [action.pl],
                scrollToPid: true,
                updatePLPPageNumber: true,
                firstFetchedPageNumber: parseInt(parseUrl(action.pl.key)?.query?.pageNumber),
                lastFetchedPageNumber: parseInt(parseUrl(action.pl.key)?.query?.pageNumber),
            };

        case UNSET_UPDATE_PAGENUMBER:
            return {
                ...state,
                updatePLPPageNumber: false,
            };

        case SET_NO_SET_SCROLL_ON_PLP:
            return {
                ...state,
                scrollToPid: false,
            };
        case PRODUCT_SIBLINGS_REQUEST:
            return {
                ...state,
                siblingsProducts: {
                    loading: true,
                    products: [],
                },
            };

        case PRODUCT_SIBLINGS_FAILURE:
            return {
                ...state,
                siblingsProducts: {
                    loading: false,
                    products: [],
                },
            };

        case PRODUCT_SIBLINGS_SUCCESS:
            return {
                ...state,
                visibleProducts: [
                    {
                        ...state.visibleProducts[0],
                        loading: false,
                        products: mapProducts(state.visibleProducts[0].products, action.body.products),
                    },
                ],
                siblingsProducts: {
                    loading: false,
                    products: action.body.products,
                },
            };
        case PRODUCT_LISTING_REQUEST:
            return {
                ...state,
                controller: action.controller,
                visibleProducts: [getProductList(action, true, state.prevNumberOfPids)],
                loading: true,
            };

        case PRODUCT_LISTING_SUCCESS:
            // If pageNumber is greater than 1 and no products returned, ie. invalid pageNumber=1000
            if (!action.body?.products?.length && action.body?.pageNumber > 1) {
                return {
                    ...state,
                    visibleProducts: [],
                    response: {
                        body: action.body,
                        headers: action.headers,
                        status: 404,
                        path: action.uid,
                        redirectUrl: action.redirectUrl,
                        redirected: action.redirected,
                    },
                    error: true,
                    loading: false,
                    appLoading: false,
                };
            }
            return {
                ...state,
                visibleProducts: [getProductList(action, false, state.prevNumberOfPids)],
                response: {
                    body: {
                        ...action.body,
                        products: null,
                    },
                    headers: action.headers,
                    status: action.status,
                    path: action.uid,
                    redirectUrl: action.redirectUrl,
                    redirected: action.redirected,
                },
                error: false,
                loading: false,
                appLoading: false,
                prevNumberOfPids: action?.body?.products?.length,
                firstFetchedPageNumber: action?.body?.pageNumber,
                lastFetchedPageNumber: action?.body?.pageNumber,
            };

        case PRODUCT_LISTING_MORE_REQUEST:
            return {
                ...state,
                loading: true,
                windowPosition: window.scrollY,
                loadPrevious: action?.options?.previous,
                visibleProducts: addProductList(state.visibleProducts, getProductList(action, true, null, 0), action?.options?.previous),
                fetchingMore: true,
            };

        case PRODUCT_LISTING_MORE_SUCCESS: {
            //prepend/append new list to existing listing
            const visibleProducts = addProductList(state.visibleProducts, getProductList(action, false, null, 60), action?.options?.previous);
            //set recycle to true if it exceed maximum number of pages
            const recycle = visibleProducts.length > action?.options?.maxPages ? true : false;
            let firstFetchedPageNumber, lastFetchedPageNumber;

            //remove first/last page if recycle is true
            //and update first/last page number
            if (recycle) {
                if (action?.options?.previous) {
                    visibleProducts.splice(visibleProducts.length - 1, 1);
                } else {
                    visibleProducts.splice(0, 1);
                }
                (firstFetchedPageNumber = action?.options?.previous ? state.firstFetchedPageNumber - 1 : state.firstFetchedPageNumber + 1),
                    (lastFetchedPageNumber = action?.options?.previous ? state.lastFetchedPageNumber - 1 : state.lastFetchedPageNumber + 1);
            } else {
                firstFetchedPageNumber =
                    state.firstFetchedPageNumber && state.firstFetchedPageNumber < action?.body?.pageNumber
                        ? state.firstFetchedPageNumber
                        : action?.body?.pageNumber;
                lastFetchedPageNumber =
                    state.lastFetchedPageNumber && state.lastFetchedPageNumber > action?.body?.pageNumber
                        ? state.lastFetchedPageNumber
                        : action?.body?.pageNumber;
            }
            return {
                ...state,
                visibleProducts,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: action.status,
                    path: action.uid,
                    redirectUrl: action.redirectUrl,
                    redirected: action.redirected,
                },
                fetchingMore: false,
                recycle,
                error: false,
                loading: false,
                scrollToPid: true,
                appLoading: false,
                firstFetchedPageNumber,
                lastFetchedPageNumber,
            };
        }

        case PRODUCT_LISTING_MORE_FAILURE:
            return {
                ...state,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: action.status,
                    path: action.uid,
                },
                error: true,
                loading: false,
                appLoading: false,
            };

        case PRODUCT_LISTING_FAILURE:
            return {
                ...state,
                response: {
                    body: action.body,
                    headers: action.headers,
                    status: action.status,
                    path: action.uid,
                },
                error: true,
                loading: false,
                appLoading: false,
                visibleProducts: [],
            };

        case RESET_PLP_STATE:
            return initialState;

        case SET_OPTIMIZELY_ALGORITHMIC_SORT_READY:
            return {
                ...state,
                optimizelyAlgorithmicSortReady: action.isReady,
            };

        default:
            return state;
    }
}
