import { getSiteMerchandisingDataByUrl } from 'app/analytics/plp';

export const trackProductReplacement = (content) => {
    let siteMerchandising = [];

    content?.[0]?.stickyTeaser?.map((stickyTeaser) => {
        const {
            position,
            item: { items = [], layoutVariant = '' },
        } = stickyTeaser;

        items?.map((teaser) => {
            siteMerchandising.push(trackProductReplacementContent(teaser, layoutVariant, position));
        });
    });

    return siteMerchandising;
};

export const trackProductReplacementContent = (
    teaser,
    layoutVariant,
    position,
    pageKey = 'product listing page',
    isAdditionalCTA = false,
    additionalCTA = [],
) => {
    const { contentID, teaserTitle, href, imagePath, anchorpoint = '' } = teaser;
    const campaignParameters = getSiteMerchandisingDataByUrl(href) || {};
    return {
        key: pageKey,
        componentType: layoutVariant,
        componentId: contentID,
        title: teaserTitle,
        imageUrl: imagePath,
        destinationUrl: href ? `${href}${anchorpoint}` : null,
        isAdditionalCTA,
        additionalCTA: Array.isArray(additionalCTA) ? additionalCTA : [additionalCTA],
        segment: '',
        modulePosition: position,
        ...campaignParameters,
    };
};

export const trackProductReplacementClick = (analytics = { page: {} }, value = {}) => {
    const { category = {} } = analytics.page;

    return {
        event: 'site merchandising - click',
        eventEffect: 'campaign click',
        category: {
            primaryCategory: 'site merchandising',
            subCategory: category.pageType,
        },
        attributes: {
            siteMerchandising: [value],
        },
    };
};
